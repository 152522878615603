import { initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore"
import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
    apiKey: "AIzaSyDoXbmY83CFDiydQfBoBcRy73YANHbe-Uw",
    authDomain: "ultamize-d4360.firebaseapp.com",
    databaseURL: "https://ultamize-d4360-default-rtdb.firebaseio.com",
    projectId: "ultamize-d4360",
    storageBucket: "ultamize-d4360.appspot.com",
    messagingSenderId: "634819640282",
    appId: "1:634819640282:web:66025922782f604da71ee3",
    measurementId: "G-FBWQYXBR92"
};


const app = initializeApp(firebaseConfig)
const auth = getAuth(app);
const db = getFirestore(app)
const functions = getFunctions(app);

export { db, app, auth, functions };