import { useEffect, useState } from 'react'
import "./messageForm.css"
import left from "../../images/left.png"
import playIcon from "../../images/play.svg";
import pauseIcon from "../../images/pause.svg";
import { useNavigate, useParams } from 'react-router-dom';
import { addMessage, getMessageById, updateMessage } from '../../services/messages.service';
import { toast } from 'react-toastify';

const MessageForm = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [text, setText] = useState<string>();
  const [sphere, setSphere] = useState<string>("health");
  const [isSaveBtnBusy, setSaveBtnBusy] = useState<boolean>(false);
  const [isPlaying, setPlaying] = useState<Record<string, boolean>>({});

  const spheres = [
    { label: 'Health', value: 'health' },
    { label: 'Relationships', value: 'relationships' },
    { label: 'Lifelong Learning', value: 'lifelong-learning' },
    { label: 'Leisure', value: 'leisure' },
    { label: 'Wealth', value: 'wealth' },
    { label: 'Creating', value: 'creating' },
    { label: 'Self control', value: 'self-control' }
  ];

  const formatMessage = (duration: number) => ({
    text,
    sphere,
    status: "Draft",
    isNameIncluded: false,
    lastUpdatedOn: new Date(),
    duration,
  });

  const saveMessage = async (id: string | undefined, message: any) => id
    ? updateMessage(id, message)
    : addMessage(message);

  const submit = async (ev: any) => {
    try {
      ev.preventDefault();
      setSaveBtnBusy(true);
      const duration = await getAudioDuration(text!, 'Joanna');
      const msg = formatMessage(duration);
      await saveMessage(params.id, msg);
      navigate('/messages');
      toast.success('Sucessfully saved a message');
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setSaveBtnBusy(false);
    }
  }

  useEffect(() => {
    const messageID = params.id;
    if (!messageID) {
      return;
    }
    const init = async () => {
      try {
        const messageDoc = await getMessageById(messageID);
        const message = messageDoc.data();
        if (!message) {
          return;
        }
        setText(message.text);
        setSphere(message.sphere);
      } catch (error) {
        console.log(error)
      }
    }
    init();
  }, [params]);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  const previewAudio = async (msg: string, voiceID: 'Matthew' | 'Joanna') => {
    setPlaying({ ...isPlaying, [voiceID]: true });
    const messageWithName = msg.replaceAll(`{{name}}`, voiceID).replaceAll(`%`, `percentage`);
    const url = `https://ultamize-d4360.web.app/generate-audio?text=${messageWithName}&voiceID=${voiceID}`;
    const audio: HTMLAudioElement = new Audio(url);
    audio.play();
    audio.addEventListener('ended', () => setPlaying({ ...isPlaying, [voiceID]: false }));
  };

  const getAudioDuration = async (msg: string, voiceID: 'Matthew' | 'Joanna'): Promise<number> => {
    setPlaying({ ...isPlaying, [voiceID]: true });
    const url = `https://ultamize-d4360.web.app/generate-audio?text=${msg}&voiceID=${voiceID}`;
    const audio: HTMLAudioElement = new Audio(url);
    return new Promise(resolve => audio.addEventListener('loadedmetadata', () => resolve(audio.duration)))
  };

  return (
    <div className='messageForm' onClick={() => navigate('/messages')}>
      <form className='right'
        onClick={ev => ev.stopPropagation()}
        onSubmit={submit}>
        <div className='header'>
          <button onClick={() => navigate('/messages')} type="button">
            <img src={left} alt="" />
          </button>
          <h3> {params?.id ? 'Edit' : 'Add'} Message </h3>
        </div>
        <div className='formContainer'>
          <h5>text</h5>
          <textarea
            className='textField'
            value={text}
            required
            onChange={(e) => setText(e.target.value)}
            rows={4}
          />
          <h5>Sphere</h5>
          {spheres.map((option, i) => (
            <div className={`sphere ${sphere === option.value ? 'active' : ''}`} key={i} onClick={() => setSphere(option.value)}>
              {option.label}
            </div>
          ))}
          <h5>Voices</h5>
          <div className="btns">
            <button
              className="btn"
              disabled={!text}
              type="button"
              onClick={() => previewAudio(text!, 'Matthew')}>
              <div className="btnTxt">Matthew</div>
              <img src={isPlaying.Matthew ? pauseIcon : playIcon} alt="Play icon" width={24} height={24} />
            </button>
            <button
              className="btn"
              disabled={!text}
              type="button"
              onClick={() => previewAudio(text!, 'Joanna')}>
              <div className="btnTxt">Joanna</div>
              <img src={isPlaying.Joanna ? pauseIcon : playIcon} alt="Play icon" width={24} height={24} />
            </button>
          </div>
        </div>
        <div className='footer'>
          <button
            className={`save ${isSaveBtnBusy && 'loading'}`}
            type='submit'
            disabled={text ? false : true}>
            Save
          </button>
          <button onClick={() => navigate('/messages')} type="button">
            Cancel
          </button>
        </div>
      </form>
    </div>
  )
}

export default MessageForm;