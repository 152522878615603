import { useEffect, useState } from 'react'
import Navbar from '../../components/navbar/Navbar'
import "./users.css"
import { filterUsers, getAllUser, getUsers, updateUser } from '../../services/user.service'
import { toast } from 'react-toastify'
import { Outlet, useNavigate } from 'react-router-dom'
import { Timestamp } from 'firebase/firestore'
import Modal from '../../components/modals/Modal'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { useAuth } from '../../contexts/auth'

interface UserFilter {
  isAdmin?: boolean;
}

const Users = () => {
  const navigate = useNavigate();
  const authUser = useAuth();
  const [users, setUsers] = useState<any[]>([]);
  const [result, setResults] = useState<any[]>([]);
  const [lastUser, setLastUser] = useState<any>();
  const [isLoadBtnBusy, setLoadBtnBusy] = useState<boolean>(false);
  const [isDisabled, setDisabled] = useState<boolean>(false);
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const [filters, setFilters] = useState<UserFilter>({ isAdmin: false });
  const [searchQuery, setQuery] = useState<string>("");
  const [activeUser, setActiveUser] = useState<any>(null);

  const statuses = [
    { label: 'All', value: 'All' },
    { label: 'Active today', value: 'Active today' },
    { label: 'Active this week', value: 'Active this week' },
    { label: 'Active this month', value: 'Active this month' },
    { label: 'Not active', value: 'Not active' },
  ];

  const toggleDefault = async (event: any, user: any) => {
    const isAdmin = event.target.checked;
    try {
      await updateUser(user.id, { isAdmin, lastActiveOn: new Date() });
      const toastMsg = `${user.displayName} ${isAdmin ? 'now has admin access' : "'s admin access revoked."}`;
      const matchAndUpdate = (msg: any) => msg.id === user.id ? ({ ...msg, isAdmin }) : msg;
      const updatedResults = result.map(matchAndUpdate);
      setResults(updatedResults);
      const updatedUsers = users.map(matchAndUpdate);
      setUsers(updatedUsers)
      toast.success(toastMsg);
    } catch (error: any) {
      toast.error(error.user);
    }
  }

  const onFilter = async (value: any, key: 'isAdmin') => {
    setQuery('');
    if (value === false) {
      const userDocs = await getUsers();
      setLastUser(userDocs.docs[userDocs.docs.length - 1]);
      const users = userDocs.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      setDisabled(false);
      setResults(users);
      return;
    }
    const updatedFilters = { ...filters, [key]: value };
    setFilters(updatedFilters);
    const filteredMessagesDoc = await filterUsers(updatedFilters);
    const filteredMessages = filteredMessagesDoc.docs
      .map(doc => ({ ...doc.data(), id: doc.id }));
    setResults(filteredMessages);
    setDisabled(true);
  }

  const getNextUsers = async (lastVisible: any) => {
    try {
      setLoadBtnBusy(true);
      const userDocs = await getUsers(lastVisible);
      setLastUser(userDocs.docs[userDocs.docs.length - 1]);
      const users = userDocs.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      if (users.length < 50) {
        setDisabled(true);
      }
      setResults([...result, ...users]);
    } catch (error: any) {
      setLoadBtnBusy(false);
      toast.error(error.user);
    } finally {
      setLoadBtnBusy(false);
    }
  }

  const searchUsers = async (event: any) => {
    const query = event.target.value;
    setQuery(query);
    if (query === "") {
      const userDocs = await getUsers();
      setLastUser(userDocs.docs[userDocs.docs.length - 1]);
      const users = userDocs.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      setDisabled(false);
      setResults(users);
      return;
    }

    const filteredusers = users.filter((msg: any) => {
      const sanitisedQuery = query.toLowerCase();
      return msg.displayName.toLowerCase().includes(sanitisedQuery);
    });
    if (filteredusers.length < 50) {
      setDisabled(true);
    }
    setResults(filteredusers);
  }

  useEffect(() => {
    if (!authUser) {
      return navigate('/sign-in');
    }
    const init = async () => {
      setLoadBtnBusy(true);
      const userDocs = await getUsers();
      const users = userDocs.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      setLastUser(userDocs.docs[userDocs.docs.length - 1]);
      setResults(users);
      setLoadBtnBusy(false);
      const usersDocs = await getAllUser()
      const allusers = usersDocs.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      setUsers(allusers);
    }
    init();
  }, [authUser, navigate]);

  const formatDay = (date: number) => {
    if (date % 10 === 1 && date !== 11) {
      return `${date}st`;
    }
    if (date % 10 === 2 && date !== 12) {
      return `${date}nd`;
    }
    if (date % 10 === 3 && date !== 13) {
      return `${date}rd`;
    }
    return `${date}th`;
  }

  const formatDate = (timestamp: Timestamp) => {
    const date = timestamp.toDate();
    const day = formatDay(date.getDate());
    const month = months[date.getMonth()];
    const time = date.toLocaleTimeString('en-US');
    return `${day} ${month}, ${date.getFullYear()}, ${time}`
  }

  const onDeleteUser = async (user: any) => {
    try {
      const data = {
        displayName: user.displayNam,
        email: user.email,
        deletedUserID: user.id
      }
      const functions = getFunctions();
      const deleteUser = httpsCallable(functions, 'deleteUserFn');
      await deleteUser(data);
      const newUsers = users.filter(obj => {
        return obj.id !== user.id;
      });
      setUsers(newUsers);
      const newResult = result.filter(obj => {
        return obj.id !== user.id;
      });
      setResults(newResult);
      const toastMsg = "User deleted Succesfully";
      toast.success(toastMsg);
      resetOperation();
    } catch (error: any) {
      toast.error(error);
    }
  }

  const deleteUser = (user: any) => {
    setActiveUser(user);
  }

  const resetOperation = () => {
    setActiveUser(null);
  }

  const getLastWeek = () => {
    const today = new Date();
    const lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
    return lastWeek;
  }
  const getLastMonth = () => {
    const today = new Date();
    const lastmonth = new Date(today.getFullYear(), today.getMonth(), 1);
    return lastmonth;
  }

  const onselect = async (value: any) => {
    if (value === 'All') {
      const userDocs = await getUsers();
      setLastUser(userDocs.docs[userDocs.docs.length - 1]);
      const users = userDocs.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      setDisabled(false);
      setResults(users);
    }
    if (value === 'Active today') {
      const dailyActiveUsers = users.filter((user: any) => {
        return user.lastActiveOn?.seconds > new Date().setUTCHours(0, 0, 0, 0) / 1000;
      })
      setResults(dailyActiveUsers);
      setDisabled(true);
    }
    if (value === 'Active this week') {
      const weeklyActiveUsers = users.filter((user: any) => {
        return user.lastActiveOn?.seconds > getLastWeek().setUTCHours(0, 0, 0, 0) / 1000;
      })
      setResults(weeklyActiveUsers);
      setDisabled(true);
    }
    if (value === 'Active this month') {
      const monthlyActiveUsers = users.filter((user: any) => {
        return user.lastActiveOn?.seconds > getLastMonth().setUTCHours(0, 0, 0, 0) / 1000;
      })
      setResults(monthlyActiveUsers);
      setDisabled(true);
    }
    if (value === 'Not active') {
      const notActiveUsers = users.filter((user: any) => {
        return !user.lastActiveOn || user.lastActiveOn?.seconds < getLastMonth().setUTCHours(0, 0, 0, 0) / 1000;
      })
      setResults(notActiveUsers);
      setDisabled(true);
    }
  }

  return (
    <>
      {!!activeUser && (
        <Modal
          title='Are you sure?'
          description={`Do you want to delete ${activeUser.displayName} from the system?`}
          onAccept={() => onDeleteUser(activeUser)}
          onDismiss={resetOperation}
        />
      )}
      <Navbar />
      <div className='container users'>
        <h1> Users </h1>
        <div className='inputWrapper'>
          <input
            type="text"
            value={searchQuery}
            placeholder='Search User.....'
            onChange={searchUsers}
            className='searchBox'
          />
          <select onChange={e => onselect(e.target.value)}
            className='activeSelect' >
            {statuses.map((option, i) => (
              <option key={i} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          <label className='switch'>
            <input className='checkbox'
              type='checkbox'
              onChange={e => onFilter(e?.target?.checked, 'isAdmin')} />
            <span className="slider round"></span>
          </label>
          <label>Only Admin</label>
        </div>
        <div className=''>
          {result.map((user: any, i: any) =>
            <div key={i} className='user'>
              <div>
                <h2> {user.displayName} </h2>
                <p> {user.email} | Last active: {user.lastActiveOn && formatDate(user.lastActiveOn)}
                </p>
              </div>
              <div>
                <label className='switch'>
                  <input className='checkbox'
                    type='checkbox'
                    value={user.id}
                    checked={user.isAdmin ? true : false}
                    onChange={(event) => toggleDefault(event, user)}
                  />
                  <span className="slider round"></span>
                </label>
                Admin
                <button onClick={() => navigate(`/users/${user.id}`)}> VIEW </button>
                <button className='danger'
                  onClick={() => deleteUser(user)}
                > DELETE </button>
              </div>
            </div>
          )}
          <button
            className={`loadbtn ${isLoadBtnBusy && 'loading'} ${isDisabled && 'disable'}`}
            disabled={isDisabled}
            onClick={() => getNextUsers(lastUser)}>
            Load More
          </button>
        </div>
      </div>
      <Outlet />
    </>
  )
}

export default Users;