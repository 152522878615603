import { useEffect, useState } from 'react'
import Navbar from '../../components/navbar/Navbar'
import male from "../../images/male.png"
import female from "../../images/female.png"
import "./messages.css"
import { filterMessages, getVoices, updateMessage } from '../../services/messages.service';
import Modal from '../../components/modals/Modal'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, Outlet, useLocation } from 'react-router-dom'
import { useAuth } from '../../contexts/auth'
import AudioStatus from '../../components/audio-status/audio-status'

interface MessageFilter {
  sphere?: string;
  status?: string;
  isIncludedInDefault?: boolean;
}
const Messages = () => {
  const authUser = useAuth();
  const navigate = useNavigate();
  const spheres = [
    { label: 'All', value: '' },
    { label: 'Health', value: 'health' },
    { label: 'Relationships', value: 'relationships' },
    { label: 'Lifelong Learning', value: 'lifelong-learning' },
    { label: 'Leisure', value: 'leisure' },
    { label: 'Wealth', value: 'wealth' },
    { label: 'Creating', value: 'creating' },
    { label: 'Self control', value: 'self-control' }
  ];
  const statuses = [
    { label: 'All', value: '' },
    { label: 'Draft', value: 'Draft' },
    { label: 'Archived', value: 'Archived' },
    { label: 'Published', value: 'Published' },
  ];
  const [messages, setMessages] = useState<any[]>([]);
  const [results, setResults] = useState<any[]>([]);
  const [filters, setFilters] = useState<MessageFilter>({ sphere: 'health' })
  const [searchQuery, setQuery] = useState<string>("");
  const [activeMessage, setActiveMessage] = useState<any>(null);
  const [activeStatus, setActiveStatus] = useState<'Published' | 'Archived' | null>(null);
  const location = useLocation();

  const toggleDefault = async (event: any, message: any) => {
    const isIncludedInDefault = event.target.checked;
    try {
      await updateMessage(message.id, { isIncludedInDefault, lastUpdatedOn: new Date() });
      const toastMsg = isIncludedInDefault
        ? "This message will be included in default playlist now."
        : "This message won't be included in default anymore";
      const updatedMessages = messages.map(msg => msg.id === message.id ? ({ ...msg, isIncludedInDefault }) : message);
      setMessages(updatedMessages);
      const updatedResults = results.map(msg => msg.id === message.id ? ({ ...msg, isIncludedInDefault }) : msg);
      setResults(updatedResults);
      toast.success(toastMsg);
    } catch (error: any) {
      toast.error(error.message);
    }
  }

  const searchMessages = (event: any) => {
    const query = event.target.value;
    setQuery(query);
    const filteredMessages = messages.filter((msg: any) => {
      const sanitisedQuery = (query || '').toLowerCase();
      return msg.text.toLowerCase().includes(sanitisedQuery);
    });
    setResults(filteredMessages);
  }

  const onFilter = async (value: any, key: 'status' | 'sphere' | 'isIncludedInDefault') => {
    setQuery('');
    const updatedFilters = { ...filters, [key]: value };
    setFilters(updatedFilters);
    const filteredMessagesDoc = await filterMessages(updatedFilters);
    const filteredMessages = filteredMessagesDoc.docs
      .map(doc => ({ ...doc.data(), id: doc.id }));
    setMessages(filteredMessages);
    setResults(filteredMessages);
  }

  const playMessage = (message: any, voiceID: 'Matthew' | 'Joanna') => {
    const messageWithName = message.text.replaceAll(`{{name}}`, voiceID).replaceAll(`%`, `percentage`);
    const url = `https://ultamize-d4360.web.app/generate-audio?text=${messageWithName}&voiceID=${voiceID}`;
    const audio: HTMLAudioElement = new Audio(url);
    audio.play();
  }

  const setOperation = (operation: 'Published' | 'Archived', message: any) => {
    setActiveStatus(operation);
    setActiveMessage(message);
  }

  const resetOperation = () => {
    setActiveStatus(null);
    setActiveMessage(null);
  }

  const publishMessage = async (id: string, text: string) => {
    const voices = await getVoices(text);
    return updateMessage(id, { status: "Published", voices, lastUpdatedOn: new Date() });
  }

  const archiveMessage = (id: string) => updateMessage(id, { status: 'Archived', lastUpdatedOn: new Date() });

  const onUpdateMessage = async ({ id, text }: any, status: 'Published' | 'Archived') => {
    try {
      status === 'Published' ? await publishMessage(id, text) : await archiveMessage(id);
      const updatedMessages = messages.map(msg => msg.id === id ? ({ ...msg, status }) : msg);
      setMessages(updatedMessages);
      setResults(updatedMessages);
      const msg = `Message status has been updated to ${status}`;
      toast.success(msg);
      resetOperation();
    } catch (error: any) {
      toast.error(error.message);
    }
  }

  useEffect(() => {
    if (!authUser) {
      return navigate('/sign-in');
    }
    const init = async () => {
      const healthMessagesDoc = await filterMessages({ sphere: 'health' });
      const healthMessages = healthMessagesDoc.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      setMessages(healthMessages);
      setResults(healthMessages);
    }
    init();
  }, [authUser, navigate]);

  useEffect(() => {
    const init = async () => {
      const filteredMessagesDoc = await filterMessages(filters);
      const filteredMessages = filteredMessagesDoc.docs
        .map(doc => ({ ...doc.data(), id: doc.id }));
      setMessages(filteredMessages);
      setResults(filteredMessages);
    }
    init();
  }, [location, filters]);

  return (
    <>
      {!!activeMessage && (
        <Modal
          title='Are you sure?'
          description={`Do you want to change the status of this messsage to ${activeStatus}?`}
          onAccept={() => onUpdateMessage(activeMessage, activeStatus!)}
          onDismiss={resetOperation}
        />
      )}
      <Navbar />
      <div className="audio container">
        <div className='wrapper'>
          <div className='heading'>
            <h1>Audio</h1>
            <button onClick={() => navigate("add")}>ADD AUDIO</button>
          </div>
          <div className='right'>
            <input
              type="text"
              placeholder='Search.....'
              value={searchQuery}
              onChange={searchMessages}
            />
            <select value={filters?.status} onChange={e => onFilter(e?.target?.value, 'status')}>
              {statuses.map((option, i) => (
                <option key={i} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            <select value={filters?.sphere} onChange={e => onFilter(e?.target?.value, 'sphere')}>
              {spheres.map((option, i) => (
                <option key={i} value={option.value}>{option.label}</option>
              ))}
            </select>
            <label className='switch'>
              <input className='checkbox'
                type='checkbox'
                onChange={e => onFilter(e?.target?.checked, 'isIncludedInDefault')} />
              <span className="slider round"></span>
            </label>
            <label>Default</label>
          </div>
        </div>
        <div>
          <table className='messages'>
            <tbody>
              {results.map((message, i) => (
                <tr key={i} className='message'>
                  <td className='textWrapper'>
                    <div className="text">{message.text}</div>
                    <div className='duration'>
                      Duration: {parseFloat(message.duration).toFixed(2)} sec
                    </div>
                  </td>
                  <td className='btns'>
                    <span className={`${message.status} status`}>
                      {message.status.toUpperCase()}
                    </span>
                    <button className='play' onClick={() => playMessage(message, 'Matthew')}>
                      <img src={male} alt="" />
                    </button>
                    <button className='play' onClick={() => playMessage(message, 'Joanna')}>
                      <img src={female} alt="" />
                    </button>
                    <button className='primary' onClick={() => navigate(`edit/${message.id}`)}>
                      Edit
                    </button>
                    <button className='success' onClick={() => setOperation("Published", message)}>
                      Publish
                    </button>
                    <button className='danger' onClick={() => setOperation("Archived", message)}>
                      Archive
                    </button>
                    <label className='switch'>
                      <input className='checkbox'
                        type='checkbox'
                        value={message.id}
                        checked={message.isIncludedInDefault ? true : false}
                        onChange={(event) => toggleDefault(event, message)}
                      />
                      <span className="slider round"></span>
                    </label>
                    Default
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Outlet />
    </>
  )
}

export default Messages;