import React, { useEffect, useState } from 'react'
import './navbar.css';
import logo from '../../images/today.svg'
import menu from '../../images/menu.svg'
import { useNavigate } from 'react-router-dom'
import { signOut } from 'firebase/auth'
import { auth } from '../../firebase'
import { useLocation } from 'react-router-dom'

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const navigate = useNavigate();
  const location = useLocation();

  const toggleNav = () => setToggleMenu(!toggleMenu);

  const logout = async () => {
    await signOut(auth);
    navigate('/sign-in');
  }

  useEffect(() => {
    const changeWidth = () => setScreenWidth(window.innerWidth);
    window.addEventListener('resize', changeWidth);
    return () => window.removeEventListener('resize', changeWidth);
  }, [])

  return (
    <header className='navbar'>
      <nav className='container'>
        <div className='logodiv'>
          <img className='logoImg' src={logo} alt='' height='36px' width='36px' />
          <h2>ultamize</h2>
        </div>
        {(toggleMenu || screenWidth > 500) && (
          <ul className='list'>
            <button className={`item ${location.pathname === '/' && 'active'}`} onClick={() => navigate('/')}> Dashboard </button>
            <button className={`item ${location.pathname === '/users' && 'active'}`} onClick={() => navigate('/users')}> Users </button>
            <button className={`item ${location.pathname === '/messages' && 'active'}`} onClick={() => navigate('/messages')}> Messages </button>
            <button className='item' onClick={logout} > Log out </button>
          </ul>
        )}
        <button onClick={toggleNav} className='btn'>
          <img className='menu_image' src={menu} alt='' height='100%' width='100%' />
        </button>
      </nav>
    </header>
  )

}

export default Navbar;