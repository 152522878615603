import './App.css';
import SignIn from './pages/signIn/SignIn';
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';
import Home from './pages/dashboard/Dashboard';
import Users from './pages/users/Users';
import Messages from './pages/messages/Messages';
import MessageForm from './pages/messageForm/MessageForm';
import { ToastContainer } from 'react-toastify';
import UserDetails from './pages/userDetails/UserDetails';
import { useEffect, useState } from 'react';
import { auth } from './firebase';
import { onAuthStateChanged } from 'firebase/auth';
import UserAuthContext from './contexts/auth';
import Loader from './components/loader/loader';

function App() {
  const [isAuthLoading, setAuthLoading] = useState<boolean>(true);
  const [user, setUser] = useState<any>(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (current) => {
      setUser(current);
      setAuthLoading(false);
    });
    return () => unsubscribe();
  }, []);

  return (
    <div className='App'>
      {isAuthLoading ? <Loader /> : (
        <UserAuthContext.Provider value={{ user }}>
          <BrowserRouter>
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/users' element={<Users />} >
                <Route path='/users/:id' element={<UserDetails />} />
              </Route>
              <Route path='/messages' element={<Messages />} >
                <Route path='/messages/add' element={<MessageForm />} />
                <Route path='/messages/edit/:id' element={<MessageForm />} />
              </Route>
              <Route path='/sign-in' element={<SignIn />} />
              <Route path="*" element={<p>There's nothing here: 404!</p>} />
            </Routes>
          </BrowserRouter>
          <ToastContainer />
        </UserAuthContext.Provider>
      )}

    </div>
  );
}

export default App;
