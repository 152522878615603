import "./dashboard.css"
import Navbar from '../../components/navbar/Navbar'
import { useEffect, useState } from "react";
import { getAllUser } from "../../services/user.service";
import { getMessages } from "../../services/messages.service";
import left from "../../images/right.svg"
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';

const Dashboard = () => {
  const navigate = useNavigate();
  const authUser = useAuth();
  const [users, setUsers] = useState<any[]>([]);
  const [messages, setMessages] = useState<any[]>([]);
  const [tempmessages, setTemmpMessages] = useState<any[]>([]);
  const [dailyActiveUser, setDailyActiveUser] = useState<any[]>([]);
  const [weeklyActiveUser, setweeklyActiveUseruUser] = useState<any[]>([]);
  const [monthlyActiveUser, setMonthlyActiveUseruUser] = useState<any[]>([]);

  const getLastWeek = () => {
    const today = new Date();
    const lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
    return lastWeek;
  }
  const getLastMonth = () => {
    const today = new Date();
    const lastmonth = new Date(today.getFullYear(), today.getMonth(), 1);
    return lastmonth;
  }

  useEffect(() => {
    if (!authUser) {
      return navigate('/sign-in');
    }
    const init = async () => {
      const usersDocs = await getAllUser();
      const users = usersDocs.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      setUsers(users);
      const dailyActiveUsers = users.filter((user: any) => {
        return user.lastActiveOn?.seconds > new Date().setUTCHours(0, 0, 0, 0) / 1000;
      })
      setDailyActiveUser(dailyActiveUsers);
      const weeklyActiveUsers = users.filter((user: any) => {
        return user.lastActiveOn?.seconds > getLastWeek().setUTCHours(0, 0, 0, 0) / 1000;
      })
      setweeklyActiveUseruUser(weeklyActiveUsers);
      const monthlyActiveUsers = users.filter((user: any) => {
        return user.lastActiveOn?.seconds > getLastMonth().setUTCHours(0, 0, 0, 0) / 1000;
      })
      setMonthlyActiveUseruUser(monthlyActiveUsers);
      const messageDocs = await getMessages();
      const messages = messageDocs.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      setMessages(messages);
    }
    init();
  }, [navigate, authUser]);

  const groupMessagesBySphere = (sphereWise: any, message: any) => {
    const current = sphereWise[message.sphere] ?? [];
    return { ...sphereWise, [message.sphere]: [...current, message] };
  };

  useEffect(() => {
    const init = async () => {
      const messageDocs = await getMessages();
      const messages = messageDocs.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      const data = messages.reduce(groupMessagesBySphere, {});
      setTemmpMessages(data);
    };
    init();
  }, []);

  return (
    <>
      <Navbar />
      <div className='dashboard container'>
        <div className='userBox'>
          <p>User</p>
          <h1>{users.length}</h1>
          <h3>By activity</h3>
          <div className='data'>
            <h4>Active today: </h4>
            <div>
              <h4> {dailyActiveUser.length}</h4>
              <img src={left} alt="" height={20} width={20} />
            </div>
          </div>
          <div className='data'>
            <h4>Active this week: </h4>
            <div>
              <h4> {weeklyActiveUser.length}  </h4>
              <img src={left} alt="" height={20} width={20} />
            </div>
          </div>
          <div className='data'>
            <h4>Active this month: </h4>
            <div>
              <h4> {monthlyActiveUser.length} </h4>
              <img src={left} alt="" height={20} width={20} />
            </div>
          </div>
          <h3>By frequency</h3>
          <div className='data'>
            <h4>Minimum: </h4>
            <div>
              <h4> 44  </h4>
              <img src={left} alt="" height={20} width={20} />
            </div>
          </div>
          <div className='data'>
            <h4>Recommended: </h4>
            <div>
              <h4> 34  </h4>
              <img src={left} alt="" height={20} width={20} />
            </div>
          </div>
          <div className='data'>
            <h4>Maximum: </h4>
            <div>
              <h4> 100  </h4>
              <img src={left} alt="" height={20} width={20} />
            </div>

          </div>
        </div>
        <div className='messageBox'>
          <p>Messages</p>
          <h1>{messages.length}</h1>
          <h3>By sphere</h3>
          {Object.entries(tempmessages).map(
            ([sphere, messages]: any) => (
              <div key={sphere} className='data'>
                <h4>{sphere} </h4>
                <div>
                  <h4> {messages.length} </h4>
                  <img src={left} alt="" />
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </>
  )
}

export default Dashboard;