import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, orderBy, query, updateDoc, where } from "firebase/firestore";
import { httpsCallable } from 'firebase/functions';
import { db, functions } from "../firebase";

export const getMessages = () => {
  const messagesRef = collection(db, "messages");
  return getDocs(messagesRef);
}

export const filterMessages = (filters: any) => {
  const messagesRef = collection(db, "messages");
  const queries = Object.entries(filters)
    .filter(([_, value]) => value)
    .map(([key, value]) => where(key, '==', value));
  return getDocs(query(messagesRef, ...queries, orderBy('lastUpdatedOn', 'desc')));
}

export const updateMessage = (id: string, message: any) => {
  const messagesRef = doc(db, 'messages', id);
  return updateDoc(messagesRef, message);
};

export const getMessageById = (id: string) => {
  const messagesRef = doc(db, "messages", id);
  return getDoc(messagesRef);
}

export const addMessage = (message: any) => {
  const messagesRef = collection(db, "messages");
  return addDoc(messagesRef, message);
}

export const deleteUserMessage = (uid: string, id: string) => {
  const messagesRef = doc(db, 'users', uid, 'messages', id);
  return deleteDoc(messagesRef);
}

export const updateUserMessage = (uid: string, id: string, duration: number) => {
  const messagesRef = doc(db, 'users', uid, 'messages', id);
  return updateDoc(messagesRef, { duration });
}

export const getDefaultMessage = (sphere: string) => {
  const messagesRef = collection(db, "messages");
  if (sphere === "All") {
    return getDocs(query(messagesRef, where('isIncludedInDefault', '==', true)));
  }
  return getDocs(query(messagesRef, where('isIncludedInDefault', '==', true), where('sphere', '==', sphere)));
}

export const getVoices = async (text: string) => {
  const getVoicesFn = httpsCallable(functions, 'saveAndGetAudioFn');
  const { data } = await getVoicesFn({ text });
  return data;
}