import { useEffect, useState } from 'react';
import spinner from '../../images/spinner.svg';
import './audio-status.css';

const AudioStatus = ({ id, path, label }: any) => {
  const domain = 'https://d3w1cmty0d4c3.cloudfront.net';
  const [isLoading, setLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<number>(0);

  const log = () => console.log(id, `${domain}/${path}`);

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      const { status: s } = await fetch(`${domain}/${path}`);
      setStatus(s);
      setLoading(false);
    }
    init();
  }, [path]);

  return (
    <div className="chip" onClick={() => log()}>
      {label}
      {isLoading ? (
        <img src={spinner} alt="" className='loader' />
      ) : (
        <span className={`dot ${status === 200 ? 'success' : 'danger'}`}></span>
      )}
    </div>
  );
}

export default AudioStatus;
