import { collection, doc, getDoc, getDocs, limit, orderBy, query, startAfter, updateDoc, where } from 'firebase/firestore';
import { db } from '../firebase';

export const getUsers = (lastVisible?: any,) => {
  const queries = [orderBy('displayName'), limit(50)];
  if (lastVisible) {
    queries.push(startAfter(lastVisible));
  }
  const userRef = query(collection(db, 'users'), ...queries);
  return getDocs(userRef);
}

export const getUserByID = (id: string) => {
  const userRef = doc(db, 'users', id);
  return getDoc(userRef);
}

export const getUserMessages = (id: string) => {
  const messagesRef = collection(db, 'users', id, 'messages');
  return getDocs(messagesRef);
}

export const getUserStats = (id: string) => {
  const statsRef = collection(db, 'users', id, 'stats');
  const queryRef = query(statsRef, orderBy('timestamp', 'desc'));
  return getDocs(queryRef);
}

export const getAllUser = () => {
  const userRef = (collection(db, 'users'));
  return getDocs(userRef);
}

export const updateUser = (id: string, message: any) => {
  const userRef = doc(db, 'users', id);
  return updateDoc(userRef, message);
}

export const filterUsers = (filters: any) => {
  const userRef = collection(db, "users");
  const queries = Object.entries(filters)
    .filter(([_, value]) => value)
    .map(([key, value]) => where(key, '==', value));
  return getDocs(query(userRef, ...queries));
}

