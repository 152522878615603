import "./userDetails.css"
import { useNavigate, useParams } from 'react-router-dom';
import left from "../../images/left.png"
import { useEffect, useState } from "react";
import { getUserByID, getUserMessages, getUserStats } from "../../services/user.service";
import { Timestamp } from "firebase/firestore";
import { deleteUserMessage, updateUserMessage } from '../../services/messages.service';
import playIcon from "../../images/playbtn.png";
import pauseIcon from "../../images/pausebtn.png";
import trashIcon from "../../images/trash.svg";
import { USER_TABS } from "../../constant";
import AudioStatus from '../../components/audio-status/audio-status';

export const enum ActivePanel {
  Messages = 'messages',
  Stats = 'stats',
}

const UserDetails = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [user, setUser] = useState<any>({});
  const [messages, setMessages] = useState<any[]>([]);
  const [results, setResults] = useState<any[]>([]);
  const [stats, setStats] = useState<any[]>([]);
  const [isPlaying, setPlaying] = useState<Record<string, boolean>>({});
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const tabs = USER_TABS;
  const [activePanel, setActivePanel] = useState<string>(ActivePanel.Stats);
  const [isNameIncluded, setNameIncluded] = useState<boolean>(false);
  const [isCustom, setCustom] = useState<boolean>(false);

  const deleteMessage = async (uid: string, id: string) => {
    try {
      await deleteUserMessage(uid, id);
      const updated = messages.filter(msg => msg.id !== id);
      const updatedResults = results.filter(msg => msg.id !== id);
      setMessages(updated);
      setResults(updatedResults);
    } catch (error: any) {
      console.log('error:', error);
    }
  }

  const formatDay = (date: number) => {
    if (date % 10 === 1 && date !== 11) {
      return `${date}st`;
    }
    if (date % 10 === 2 && date !== 12) {
      return `${date}nd`;
    }
    if (date % 10 === 3 && date !== 13) {
      return `${date}rd`;
    }
    return `${date}th`;
  }

  const formatDateAndTime = (timestamp: Timestamp) => {
    const date = timestamp.toDate();
    const day = formatDay(date.getDate());
    const month = months[date.getMonth()];
    const time = date.toLocaleTimeString('en-US');
    return `${day} ${month}, ${date.getFullYear()}, ${time}`
  }

  const formatOnlyDate = (timestamp: Timestamp) => {
    const date = timestamp.toDate();
    const day = formatDay(date.getDate());
    const month = months[date.getMonth()];
    return `${month} ${day}, ${date.getFullYear()} `
  }

  const formateTime = (seconds: any) => {
    const minute = ~~(seconds / 60);
    const extrasecond = ~~(seconds % 60);
    return `${minute} Min ${extrasecond} Seconds`
  }

  const previewAudio = async (msg: any, voiceID: 'Matthew' | 'Joanna') => {
    setPlaying({ ...isPlaying, id: msg.id, [voiceID]: true });
    const url = `https://ultamize-d4360.web.app/generate-audio?text=${msg.text}&voiceID=${voiceID}`;
    const audio: HTMLAudioElement = new Audio(url);
    audio.addEventListener('ended', () => setPlaying({ ...isPlaying, [voiceID]: false }));
    audio.play();
  };

  const filterMessages = (hasName: boolean, custom: boolean) => {
    const isMatch = (msg: any) => Boolean(msg.isNameIncluded) === hasName && ((msg.sphere === 'custom') === custom)
    const filtered = messages.filter(isMatch);
    setResults(filtered);
    setNameIncluded(hasName);
    setCustom(custom);
  };

  const getAudioDuration = async (msg: any): Promise<number> => {
    const url = `https://d3w1cmty0d4c3.cloudfront.net/${msg.voices.Matthew}`;
    const audio: HTMLAudioElement = new Audio(url);
    return new Promise(resolve => audio.addEventListener('loadedmetadata', () => resolve(audio.duration)))
  };

  const updateMessageDuration = async (userID: string, msg: any) => {
    try {
      const duration = await getAudioDuration(msg);
      await updateUserMessage(userID, msg.id, duration);
      const updated = messages.map(m => m.id === msg.id ? { ...m, duration } : m);
      setMessages(updated);
      const updatedResults = results.map(m => m.id === msg.id ? { ...m, duration } : m);
      setResults(updatedResults);
    } catch (error: any) {
      console.log('error:', error);
    }
  }

  useEffect(() => {
    const userId = params.id;
    if (!userId) {
      return;
    }
    const init = async () => {
      try {
        const userDoc = await getUserByID(userId);
        const user = userDoc.data();
        if (!user) {
          return;
        }
        setUser({ ...user, id: userId });
        const messagesDoc = await getUserMessages(userId);
        const msgs = messagesDoc.docs.map(doc => ({ ...doc.data(), id: doc.id }));
        setMessages(msgs);
        setResults(msgs);
        const statsDoc = await getUserStats(userId);
        const stats = statsDoc.docs.map(doc => ({ ...doc.data(), id: doc.id }));
        setStats(stats);
      } catch (error) {
        console.log(error);
      }
    }
    init();
  }, [params]);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <div className='userDetails' onClick={() => navigate('/users')} >
      <div className='right' onClick={ev => ev.stopPropagation()}  >
        <div className='header'>
          <button onClick={() => navigate('/users')} type="button">
            <img src={left} alt="" />
          </button>
          <h2>{user.displayName} </h2>
        </div>
        <div className="content">
          <div className="userContainer">
            <div className="userImage">
              <h1>{user.displayName && user.displayName.charAt(0)}</h1>
            </div>
            <h2>{user.displayName} </h2>
            <h4>{user.email}</h4>
            <p> Last active: {user.lastActiveOn && formatDateAndTime(user.lastActiveOn)}</p>
          </div>
          <div className="messagesWrapper">
            <div className="tabs">
              {tabs.map((tab, i) => (
                <h1
                  key={tab.value}
                  className={`tab ${activePanel === tab.value && 'active'}`}
                  onClick={() => setActivePanel(tab.value)}
                >
                  {tab.label}
                </h1>
              ))}
            </div>
            <div className="panel">
              {
                {
                  [ActivePanel.Messages]: (
                    <>
                      <div className="filter">
                        <div className="checkbox">
                          <input
                            id="onlyWithName"
                            type="checkbox"
                            checked={isNameIncluded}
                            onChange={ev => filterMessages(ev.target.checked, isCustom)}
                          />
                          <label htmlFor="onlyWithName">Only with name</label>
                        </div>
                        <div className="checkbox">
                          <input
                            id="onlyCustom"
                            type="checkbox"
                            checked={isCustom}
                            onChange={ev => filterMessages(isNameIncluded, ev.target.checked)}
                          />
                          <label htmlFor="onlyCustom">Only custom</label>
                        </div>
                      </div>
                      <div className="messages">
                        {results.map((message: any, i: any) =>
                          <div key={i} className="message">
                            <div className="text">
                              <div className="sphere">{message.sphere}</div>
                              <p> {message.text} </p>
                              <p> {parseFloat(message.duration).toFixed(2)} Sec</p>
                            </div>
                            <button
                              className="btn"
                              type="button"
                              onClick={() => previewAudio(message, 'Matthew')}>
                              <img src={isPlaying.id === message.id ? pauseIcon : playIcon} alt="Play icon" />
                            </button>
                            <button
                              className="deleteBtn"
                              type="button"
                              onClick={() => deleteMessage(user.id, message.id)}>
                              <img src={trashIcon} alt="Trash icon" />
                            </button>
                            <div className="statuses">
                              <button
                                type='button'
                                onClick={() => updateMessageDuration(user.id, message)}>
                                @
                              </button>
                              <AudioStatus
                                id={message.id}
                                label="Matthew"
                                path={message?.voices?.Matthew}
                              />
                              <AudioStatus
                                id={message.id}
                                label="Joanna"
                                path={message?.voices?.Joanna}
                              />
                              {message?.isNameIncluded && (
                                <>
                                  <AudioStatus
                                    id={message.id}
                                    label="Matthew(n)"
                                    path={message?.voicesWithName?.Matthew}
                                  />
                                  <AudioStatus
                                    id={message.id}
                                    label="Joanna(n)"
                                    path={message?.voicesWithName?.Joanna}
                                  />
                                </>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  ),
                  [ActivePanel.Stats]: (
                    <div>
                      {stats.map((stats: any, i: any) =>
                        <div key={i} className="stats">
                          <p>{stats.timestamp && formatOnlyDate(stats.timestamp)}</p>
                          <p> {formateTime(stats.listenTime)}</p>
                        </div>
                      )}
                    </div>
                  ),
                }[activePanel]
              }
            </div>
          </div>
        </div>
        <div className='footer'>
          <button className="save" onClick={() => navigate('/users')} type="button">
            Done
          </button>
        </div>
      </div>
    </div>
  )
}

export default UserDetails;