import { useEffect, useState } from 'react';
import "./modal.css"

interface ModalProps {
  title: string;
  description: string;
  onAccept: () => void;
  onDismiss: () => void;
}

const Modal = ({ title, description, onAccept, onDismiss }: ModalProps) => {
  const [isConfirmBtnBusy, setConfirmBtnBusy] = useState<boolean>(false);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);
  return (
    <div className='modal' onClick={onDismiss}>
      <div className='contain' onClick={ev => ev.stopPropagation()}>
        <h2>{title}</h2>
        <p>{description}</p>
        <div className="btns">
          <button className='danger'
            onClick={onDismiss}>
            Cancel
          </button>
          <button className={`success ${isConfirmBtnBusy && 'loading'}`}
            onClick={() => {
              setConfirmBtnBusy(true);
              onAccept();
            }}>
            Yes, confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal


