import { useState } from 'react'
import './signIn.css'
import logo from '../../images//logo.jpg'
import openEye from '../../images/openEye.svg'
import closeEye from '../../images/closeEye.svg'

import background from '../../images//background.jpg'
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth, db } from '../../firebase';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore'

const SignIn = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [errorMessage, setError] = useState<string | null>('');
  const [isShown, setIsShown] = useState(false);
  const [isLoading, setLoading] = useState<boolean>(false);

  const togglePassword = () => setIsShown(!isShown);

  const checkIfAdmin = async ({ uid }: any): Promise<boolean> => {
    const userRef = doc(db, "users", uid);
    const userDoc = await getDoc(userRef);
    const user = userDoc.data();
    if (!userDoc.exists()) {
      return false;
    }
    return user?.isAdmin;
  }

  const submit = async (ev: any) => {
    try {
      ev.preventDefault();
      setError(null);
      setLoading(true);
      const credentials = await signInWithEmailAndPassword(auth, email, password);
      const user = credentials.user;
      const isAdmin = await checkIfAdmin(user);
      if (!isAdmin) {
        const msg = `You don't have administrative privileges to access this, contact admins to get access.`;
        setLoading(false);
        return setError(msg);
      }
      navigate('/');
    } catch (error: any) {
      setLoading(false);
      setError(error.message);
    }
    finally {
      setLoading(false);
    }
  }

  return (
    <div className='signin'>
      <div className='left'>
        <div className='logo'>
          <img src={logo} alt='' height='100px' width='100px' />
          <p className='quote'>Daily life programing</p>
        </div>
        <form onSubmit={submit} >
          <div className="field">
            <input
              id="email"
              type='text'
              name='email'
              placeholder='Enter email adress'
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="field ">
            <input
              id="password"
              className='password_ip'
              type={isShown ? "text" : "password"}
              name='password'
              placeholder='Enter password'
              onChange={(e) => setPassword(e.target.value)}
            />
            <button type='button' className='passwordBtn' onClick={togglePassword}>
              {isShown
                ? <img src={openEye} alt="" height='18px' width='18px' />
                : <img src={closeEye} alt="" height='18px' width='18px' />
              }
            </button>
          </div>
          <button
            className={`signinBtn ${isLoading && 'loading'}`}
            type='submit'
            disabled={!email || !password}>
            Login
          </button>
          <div className={`error ${errorMessage && 'active'}`}>
            {errorMessage}
          </div>
        </form>
      </div>
      <div className='right'>
        <img src={background} alt='' height='100%' width='100%' />
      </div>
    </div>
  )
}

export default SignIn;
